






















































































































































import {Vue, Component, Prop} from 'vue-property-decorator'
import PoweredBy from '@/components/PoweredBy.vue'
import TokensPriceTable from '@/components/tokensprice/TokensPriceTable.vue'
import LastPriceUpdated from '@/components/LastPriceUpdated.vue'
import {Dapp} from '@/model/resource/Dapp'
import {PoweredByItem} from '../homepage/explore-tokens-and-nfts/ExploreTokensPoweredBy.vue'
import {TokensTableItemsCollection} from '@/model/collection/TokensTableItemsCollection'
import {NftCollectionCollection} from '@/model/collection/NftCollectionCollection'
import NftCollectionTable from '@/components/nfts/NftCollectionTable.vue'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'

@Component({
  components: {
    NftCollectionTable,
    LastPriceUpdated,
    TokensPriceTable,
    PoweredBy,
  },
})
export default class DappDetailedTokensNftsSection extends MixinScreenSize {
  @Prop({type: Dapp, required: true}) dapp!: Dapp
  @Prop({type: TokensTableItemsCollection, required: true})
  tokensTableItemsCollection!: TokensTableItemsCollection
  @Prop({type: NftCollectionCollection, required: true})
  nftCollectionCollection!: NftCollectionCollection

  isTokensTableDisplayed = true

  tokensTableLastTimeUpdated: string | null = null

  tokensTablePoweredByList = [
    {
      url: 'https://flamingo.finance/',
      alt: 'flamingo icon',
      filename: 'flamingo-icon.svg',
    } as PoweredByItem,
    {
      url: 'https://www.forthewin.network/#/',
      alt: 'ftwicon',
      filename: 'for-the-win-logo.svg',
    } as PoweredByItem,
    {
      url: 'https://neofura-1.gitbook.io/api-docs/',
      alt: 'magnet icon',
      filename: 'magnet-icon.svg',
    } as PoweredByItem,
  ]

  nftsPoweredByList = [
    {
      url: 'https://ghostmarket.io/',
      alt: 'ghostmarket icon',
      filename: 'ghostmarket-icon.svg',
    } as PoweredByItem,
  ]

  get hasTokens() {
    return (
      this.tokensTableItemsCollection.items.length > 0 &&
      this.tokensTableItemsCollection.items[0].$id !== 0
    )
  }

  handleSwitchTable(active: boolean) {
    this.isTokensTableDisplayed = active
  }

  async mounted() {
    if (this.tokensTableItemsCollection.isEmpty()) {
      this.isTokensTableDisplayed = false
      return
    }

    this.handleChangeTokensTableLastTimeUpdated(
      this.tokensTableItemsCollection.lastTimeUpdatedFormatted
    )
  }

  handleChangeTokensTableLastTimeUpdated(lastTimeUpdated: string) {
    this.tokensTableLastTimeUpdated = lastTimeUpdated
  }
}
