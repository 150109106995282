


















import {Component, Prop} from 'vue-property-decorator'
import {NftCollectionCollection} from '@/model/collection/NftCollectionCollection'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {SimpleListNftCollectionSchema} from '@/schema/resource/NftCollection/SimpleListNftCollectionSchema'
import ScrollableTable from '@/components/ScrollableTable.vue'
import GenericToggleButton from '@/components/misc/GenericToggleButton.vue'
import PoweredBy from '@/components/PoweredBy.vue'
import ShowAllCollectionsToggle from '@/components/ShowAllCollectionsToggle.vue'
import LastPriceUpdated from '@/components/LastPriceUpdated.vue'
import {Dapp} from '@/model/resource/Dapp'

@Component({
  components: {
    ShowAllCollectionsToggle,
    GenericToggleButton,
    ScrollableTable,
    PoweredBy,
    LastPriceUpdated,
  },
})
export default class NftCollectionTable extends MixinScreenSize {
  @Prop({type: Number, default: null}) perPage!: number
  @Prop({type: Boolean, default: false}) showViewAll!: boolean
  @Prop({type: Boolean, default: false}) isHomepage!: boolean
  @Prop({type: Boolean, default: false}) isDappDetailedPage!: boolean
  @Prop({type: NftCollectionCollection, required: true})
  collection!: NftCollectionCollection
  @Prop({type: Dapp, required: false}) dapp?: Dapp

  schema = new SimpleListNftCollectionSchema()
}
